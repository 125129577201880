import React from 'react'
import { Head } from '~/components'
import Banner from '~/components/Banner'
import Breadcrumb from '~/components/Breadcrumb'
import TravelOptions from '~/components/TravelOptions'
import VipLounge from '~/components/VipLounge'
import Footer from '~/components/Footer'
import { Router } from '@reach/router'

import bannerTurismo from '~/assets/img/banner-turismo.png'

const PacoteTuristico = () => (
  <>
    <Head title={'Pacotes Turísticos - ' + process.env.GATSBY_SITE_NAME} />
    <Banner
      bannerBackgroundColor="#8BE5E5"
      bannerTitle="Pacotes Turísticos"
      bannerSubtitle="Os melhores destinos para sua viagem você encontra aqui."
      bannerImage={bannerTurismo}
      bannerImageAlt="Foto de duas pessoas de férias na piscina"
      imageBottomPosition={-2}
    />
    <Breadcrumb />
    <TravelOptions />
    <VipLounge />
    <Footer />
  </>
)

const PacoteTuristicoGrouped = ({ slug }) => (
  <>
    <Head title={'Pacotes Turísticos - ' + process.env.GATSBY_SITE_NAME} />
    <Banner
      bannerBackgroundColor="#8BE5E5"
      bannerTitle="Pacotes Turísticos"
      bannerSubtitle="Os melhores destinos para sua viagem você encontra aqui."
      bannerImage={bannerTurismo}
      bannerImageAlt="Foto de duas pessoas de férias na piscina"
      imageBottomPosition={-2}
    />
    <Breadcrumb />
    <TravelOptions slug={slug} />
    <VipLounge />
    <Footer />
  </>
)

export default function PacotesTuristicos() {
  return (
    <Router>
      <PacoteTuristico path="/pacotes-turisticos" />
      <PacoteTuristicoGrouped path="/pacotes-turisticos/:slug" />
    </Router>
  )
}
