// extracted by mini-css-extract-plugin
export var backgroundMissingImage = "TravelOptions-module--background-missing-image--tUQSn";
export var containerImages = "TravelOptions-module--container-images--FXP88";
export var departing = "TravelOptions-module--departing--0dy1-";
export var descriptions = "TravelOptions-module--descriptions--0OiES";
export var destination = "TravelOptions-module--destination--2ZnWU";
export var installmentBar = "TravelOptions-module--installment-bar--V4NwY";
export var loadMore = "TravelOptions-module--load-more--eFQSq";
export var returning = "TravelOptions-module--returning--GlwMa";
export var tags = "TravelOptions-module--tags--wkk16";
export var travelCard = "TravelOptions-module--travel-card--Pn1uE";
export var travelCardBody = "TravelOptions-module--travel-card-body--JufJb";
export var travelCardHeader = "TravelOptions-module--travel-card-header--uAjZ0";
export var travelCardsWrapper = "TravelOptions-module--travel-cards-wrapper--kNMnL";
export var travelOptionsContainer = "TravelOptions-module--travel-options-container--LmRAf";