import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { useApi } from '~/api'
import cn from 'classnames'

import * as st from '~/assets/styl/TravelOptions.module.styl'

import rightArrow from '~/assets/img/svg/right-arrow.svg'
import leftArrow from '~/assets/img/svg/left-arrow.svg'
import { useMediaQuery } from 'react-responsive'
import { LazyImage } from 'react-lazy-images'
import SoldOutTag from './SoldOutTag'

type Props = {
  image: string
  name: string
  region: string
  return_at?: string
  exit_at?: string
  unavailable?: boolean
  slug?: string
  payment: string
}

export default function TravelOptions({
  image,
  name,
  region,
  return_at,
  exit_at,
  slug,
}: Props) {
  const [perPageMobile, setPerPageMobile] = useState(4)
  const [perPage, setPerPage] = useState(12)
  const travelOptionsUnfiltered = useApi([], 'packages')
  const travelOptions = travelOptionsUnfiltered.filter(
    (t) => !slug || t.name === slug
  )

  if (travelOptionsUnfiltered.length && travelOptions.length === 0) {
    navigate('/404')
  }

  function loadMore() {
    setPerPage(perPage + 12)
    setPerPageMobile(perPageMobile + 4)
  }

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  return (
    <div className={st.travelOptionsContainer}>
      <ul className={st.travelCardsWrapper}>
        {(!isDesktop &&
          travelOptions.slice(0, perPageMobile).map((card, index) => (
            <li key={index} className={st.travelCard}>
              <Link to={'/pacotes/' + card.slug + '/'}>
                <div
                  className={cn(st.travelCardHeader, {
                    [st.backgroundMissingImage]: !card.image,
                  })}
                >
                  <div className={st.containerImages}>
                    <LazyImage
                      src={card.image}
                      alt={card.name}
                      placeholder={({ imageProps, ref }) => (
                        <img ref={ref} src={card.image} alt={card.name} />
                      )}
                      actual={({ imageProps }) => (
                        <img {...imageProps} style={{ width: '100%' }} />
                      )}
                    />
                  </div>
                  {card.payment && (
                    <div className={st.installmentBar}>
                      <span>{card.payment}</span>
                    </div>
                  )}
                </div>
                <div className={st.travelCardBody}>
                  <div className={st.destination}>
                    <span>
                      <b>{card.name}</b> - {card.region}
                    </span>
                    {card.unavailable && <SoldOutTag />}
                  </div>
                  <div className={st.descriptions}>
                    <div className={st.departing}>
                      <img src={rightArrow} />
                      <span>Saída: {card.exit_at}</span>
                    </div>
                    <div className={st.returning}>
                      <img src={leftArrow} />
                      <span>Retorno: {card.return_at}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))) ||
          travelOptions.slice(0, perPage).map((card, index) => (
            <li key={index} className={st.travelCard}>
              <Link to={'/pacotes/' + card.slug + '/'}>
                <div
                  className={cn(st.travelCardHeader, {
                    [st.backgroundMissingImage]: !card.image,
                  })}
                >
                  <div className={st.containerImages}>
                    <LazyImage
                      src={card.image}
                      alt={card.name}
                      placeholder={({ imageProps, ref }) => (
                        <img ref={ref} src={card.image} alt={card.name} />
                      )}
                      actual={({ imageProps }) => (
                        <img {...imageProps} style={{ width: '100%' }} />
                      )}
                    />
                  </div>
                  {card.payment && (
                    <div className={st.installmentBar}>
                      <span>{card.payment}</span>
                    </div>
                  )}
                </div>
                <div className={st.travelCardBody}>
                  <div className={st.destination}>
                    <span>
                      <b>{card.name}</b> - {card.region}
                    </span>
                    {card.unavailable && <SoldOutTag />}
                  </div>
                  <div className={st.descriptions}>
                    <div className={st.departing}>
                      <img src={rightArrow} />
                      <span>Saída: {card.exit_at}</span>
                    </div>
                    <div className={st.returning}>
                      <img src={leftArrow} />
                      <span>Retorno: {card.return_at}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
      {(isDesktop && travelOptions.length > perPage && (
        <button
          className={cn(st.loadMore, 'hide-on-mobile')}
          onClick={loadMore}
        >
          Carregar mais pacotes
        </button>
      )) ||
        (!isDesktop && travelOptions.length > perPageMobile && (
          <button
            className={cn(st.loadMore, 'hide-on-desktop')}
            onClick={loadMore}
          >
            Carregar mais pacotes
          </button>
        ))}
    </div>
  )
}
